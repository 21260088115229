<template>
	<v-app class="login no-auth">
		<div
			class="tw-h-screen tw-overflow-x-hidden tw-overflow-y-auto tw-flex tw-flex-col"
		>
			<div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-flex-1">
				<div
					class="tw-place-content-center tw-px-4 md:tw-px-0 tw-hidden lg:tw-grid"
				>
					<div class="tw-text-center tw-max-w-md">
						<img
							class="tw-h-52 tw-object-contain tw-mx-auto"
							src="@/assets/images/logo.png"
							alt
							srcset
						/>
						<h3
							class="tw-font-semibold tw-text-2xl md:tw-text-3xl 3xl:tw-text-4xl tw-mt-6 md:tw-mt-10 3xl:tw-mt-14"
						>
							{{ $t('login.welcomeToMachla') }}	</h3>
						<p class="3xl:tw-text-xl tw-mt-3 3xl:tw-mt-5 tw-text-70">
							{{ $t('login.buyingAndSellingMakeEasy') }}	</p>
						<div class="tw-mt-64">
							<!-- <v-btn dark color="#A53E50" block>Login</v-btn> -->
							<!-- class="tw-mt-4 md:tw-mt-7" -->
							<v-btn outlined color="#EEEEEE" block>
								<span class="tw-text-black">{{ $t('sign-up') }}</span>
							</v-btn>
						</div>
					</div>
				</div>
				<div class="tw-bg-72">
					<div class="lg:tw-my-20 tw-my-8 tw-mx-4 sm:tw-mx-8 xl:tw-mx-24">
						<div>
							<h2 class="tw-text-27px tw-text-white tw-mb-12">
								{{ $t('user-agreement') }}	</h2>
							<div class="tw-text-white tw-opacity-80 tw-mb-7">
								Sed ut perspiciatis unde omnis iste natus error sit
								voluptatem accusantium doloremqueil tis et quasi
								architecto beatae vitae dicta sunt explicabo. Nemo enim
								ipsam voluptatemu gni dolores eos qui ratione voluptatem
								sequi nesciunt. Neque porro quisquam est, qui do Sed ut
								perspiciatis unde omnis iste natus error sit voluptatem
								accusantium doloremqudt Sed ut perspiciatis unde omnis
								iste natus error sit voluptatem accusantium doloremqueil
								tis et quasi architecto beatae vitae dicta sunt
								explicabo. Nemo enim ipsam voluptatemu gni dolores eos
								qui ratione voluptatem sequi nesciunt. Neque porro
								quisquam est, qui do Sed ut perspiciatis unde omnis iste
								natus error sit voluptatem accusantium doloremqudt Sed
								ut perspiciatis unde omnis iste natus error sit
								voluptatem accusantium doloremqueil tis et quasi
								architecto beatae vitae dicta sunt explicabo. Nemo enim
								ipsam voluptatemu gni dolores eos qui ratione voluptatem
								sequi nesciunt. Neque porro quisquam est, qui do Sed ut
								perspiciatis unde omnis iste natus error sit voluptatem
								accusantium doloremqudt Sed ut perspiciatis unde omnis
								iste natus error sit voluptatem accusantium doloremqueil
								tis et quasi architecto beatae vitae dicta sunt
								explicabo. Nemo enim ipsam voluptatemu gni dolores eos
								qui ratione voluptatem sequi nesciunt. Neque porro
								quisquam est, qui do Sed ut perspiciatis unde omnis iste
								natus
							</div>
							<div
								class="tw-text-white tw-text-19px tw-font-medium tw-mb-11"
							>
								<div
									class="tw-flex tw-items-center tw-space-s-5 tw-mb-2"
								>
									<span
										class="tw-rounded-full tw-block tw-bg-F2 tw-w-3 tw-h-3"
									></span>
									<div class>
										Sed ut perspiciatis unde omnis iste natus error
									</div>
								</div>
								<div
									class="tw-flex tw-items-center tw-space-s-5 tw-mb-2"
								>
									<span
										class="tw-rounded-full tw-block tw-bg-F2 tw-w-3 tw-h-3"
									></span>
									<div class>
										Sed ut perspiciatis unde omnis iste natus error
									</div>
								</div>
								<div class="tw-flex tw-items-center tw-space-s-5">
									<span
										class="tw-rounded-full tw-block tw-bg-F2 tw-w-3 tw-h-3"
									></span>
									<div class>
										Sed ut perspiciatis unde omnis iste natus error
									</div>
								</div>
							</div>
							<div class="tw-text-white tw-opacity-80">
								Sed ut perspiciatis unde omnis iste natus error sit
								voluptatem accusantium doloremqueil tis et quasi
								architecto beatae vitae dicta sunt explicabo. Nemo enim
								ipsam voluptatemu gni dolores eos qui ratione voluptatem
								sequi nesciunt. Neque porro quisquam est, qui do Sed ut
								perspiciatis unde omnis iste natus error sit voluptatem
								accusantium doloremqudt Sed ut perspiciatis unde omnis
								iste natus error sit voluptatem accusantium doloremqueil
								tis et quasi architecto beatae vitae dicta sunt
								explicabo. Nemo enim ipsam voluptatemu gni dolores eos
								qui ratione voluptatem sequi nesciunt. Neque porro
								quisquam est, qui dol Sed ut perspiciatis unde omnis
								iste natus
							</div>
						</div>
						<v-btn
							@click="handleAcceptTermAndCondition"
							class="2xl:tw-mt-14 tw-mt-8"
							dark
							depressed
							color="#A53E50"
							block
							:loading="loading"
						>
							<span>{{$t('accept-proceed')}}</span>
						</v-btn>
					</div>
				</div>
			</div>
		</div>
	</v-app>
</template>

<script>
import { mapActions } from 'vuex'
import store from '../store'

export default {
	name: 'Login',
	components: {},
	data() {
		return {
			loading: false,
			error: {
				dialog: false,
				message: '',
			},
		}
	},
	mounted() {
		let user = store.getters['auth/$currentUser']
		user.isAcceptTerms && this.$router.push('/')
	},
	methods: {
		...mapActions('auth', ['acceptTermsAndcondition', 'fetchProfile']),
		async handleAcceptTermAndCondition() {
			this.loading = true
			const [err] = await this.acceptTermsAndcondition()
			if (!err) {
				// await this.fetchProfile();
				// let { redirect } = { ...this.$route.query };
				// console.log(redirect);
				console.log('success')
				this.$router.replace('/')
			}
			this.loading = false
		},
	},
}
</script>

<style lang="scss" type="text/scss" scoped></style>
